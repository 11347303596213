export const bookingTypes = {
    CLEAN_BOOKING_DETAILES: 'clean',
    SELECTED_RESOURCE: 'selectedResource',
    RESERVATION_SLOT: 'reservationSlot',
    USER_DETAILS: 'userDetails',
    GET_RESOURCE_PRICING_RULES: "getResourcePricingRules",
    GET_RESOURCE_DEFAULT_PRICING: "getResourceDefaultPricing",
    PRICING_RULE_DETAILS: "pricingRuleDetails",
    EDIT_USER_DETAILS: 'editUserDetails',
    GET_RESOURCE_DETAILS: 'GET_RESOURCE_DETAILS',
    GET_BOOKING_SLOTS: 'GET_BOOKING_SLOTS',
    MAKE_RESERVATION: 'MAKE_RESERVATION',
    SET_SELECTEDRESOURCES: 'SET_SELECTEDRESOURCES',
    CONFIRM_RESERVATION: 'confirmReservation',
    ERROR_CONFIRM_RESERVATION: 'errorConfirmReservation',
    AMOUNT_VALIDATION: 'amountValidation',
    EDIT_RESERVATION: 'editReservation',
    CANCEL_RESERVATION: 'cancelReservation',
    BOOKINGS: 'bookings',
    RESOURCE_TYPE: 'resourceType',
    IS_DYNAMIC_BOOKING: 'isDynamicWeekly',
    BOOKED_DATES: 'bookedDates',
    ERROR: 'error',
    B2B_USER_DETAILS: 'b2bUserDetails',
    EMAIL_HASH: 'emailHash',
    USER_TYPE: 'userType',
    RESTORE_BOOKING: 'restoreBooking',
    SET_ACTIVE_BOOKINGS: 'setActiveBookings',
    SET_OLD_BOOKINGS: 'setOldBookings',
    SET_SELECTED_BOOKING: 'setSelectedBooking',
    CLEAN_RESERVATION_SLOT: 'cleanReservationSlot',
    MARKETING_UPDATES: 'marketingUpdates',
    POPULATE_BOOKING: 'populateBooking',
    EVENT_TYPE: 'eventType',
    BOOKING_REFUND_PERIOD: 'bookingRefundPeriod',
    RESERVATION_QR_CODE: 'reservationQrCode',
    CLEAN_CONFIRM_RESERVATION: 'cleanConfirmReservation'
}