import { eventTypes } from "../constants/eventTypes.constants"
import { bookingTypes } from "../redux/types"

const selectedResource = {
    name: "",
    translated_name: "",
    quantity: "",
    site_id: "",
    confirmation_type: "",
    category: "",
    properties: {
        description: "",
        hidden_flex_admin_duration: "",
        hidden_login_required: "",
        hidden_special_url_access: ""
    },
    photos: {},
    time_unit: 0,
    sharing_mode: "",
    start_hour: "",
    end_hour: "",
    start_quarters: "",
    unit_price: "",
    price_type: "",
    currency: "",
    is_overnight_stay: null,
    max_quantity_per_rental: null,
    is_published: "",
    is_listed: "",
    min_rental_time: "",
    max_rental_time: "",
    start_times: null,
    min_hours_to_rental: "",
    max_days_to_rental: "",
    event_dates: null,
    resource_admin_id: null,
    resource_admin_name: null,
    resource_admin_email: null,
    min_time_between_rentals: 0,
    prepayment_amount: "",
    prepayment_amount_valid_until: 0
}

export const reservationSlot = {
    date: "",//moment().format('YYYY-MM-DD'),
    nextAvailableDate: "",
    tmpCheckInDate: "",
    timeIn: "",
    timeOut: "",
    noOfGuests: "",
    isCheckedFullDay: false,
    quantity: 1,
    checkOutDate: "",
    tmpCheckOutDate: "",
    eventAvailableDates: [],
    unit_assignment: "",
    isSoldOut: false,
    isPastEvent: false
}

const userDetails = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    phonePrefix: "",
    store: "",
    userType: "",
    marketingUpdates: false,
    payment: {}
}

export const confirmReservation = {
    resource_id: "",
    reservation_id: "",
    refundPeriod: null,
    reservation_qr_code: ''
}

export const errorConfirmReservation = {
    message: "",
    errors: ""
}

export const amountValidation = {
    isError: false,
    message: ''
}

export const booking = {
    bookings: {
        reservationId: null
    },
    selectedResource: {
        ...selectedResource
    },
    reservationSlot: {
        ...reservationSlot
    },
    userDetails: {
        ...userDetails
    },
    confirmReservation: {
        ...confirmReservation
    },
    errorConfirmReservation: { 
        ...errorConfirmReservation 
    },
    amountValidation: {
        ...amountValidation
    },
    bookedDates: {},
    isDynamicWeekly: false,
    resourceType: "quarterly",
    error: null,
    b2bUserDetails: null,
    emailHash: "",
    activeBookings: [],
    oldBookings: [],
    selectedBooking: {},
    [bookingTypes.EVENT_TYPE]: eventTypes.SINGLE
}